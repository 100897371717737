import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyBgG4MwVXnSP9zFsQJslfHCSziHNBKRWRQ",
  authDomain: "hooshmand-enterprises.firebaseapp.com",
  projectId: "hooshmand-enterprises",
  storageBucket: "hooshmand-enterprises.appspot.com",
  messagingSenderId: "101706506798",
  appId: "1:101706506798:web:c270112fec28fd5e54f5bf",
  measurementId: "G-ML55DXL6J9"
};

const app = initializeApp(firebaseConfig)
getAnalytics(app)


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
