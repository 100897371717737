import React from 'react';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <section className="App-page App-header">
        <h2>
          Founded by<br/>
          <a href='https://habeeb.bio' target={'_blank'} rel="noreferrer noopener">Habeeb Hooshmand</a>
        </h2>
        <header>
          <img src='/static/app-logo-transparent.webp' className="App-logo" alt="logo" />
        </header>
        <section className="App-contact">
          <a href='mailto:contact@hooshmandenterprises.com?subject=Contact request' target={'_blank'} rel="noreferrer">contact@hooshmandenterprises.com</a>
          <p>
            Custom digital software solutions.
          </p>
        </section>
      </section>
      <section className='App-page'>
        <h2>Products</h2>
        <section className='App-products'>
          <div className='App-product'>
            <img width='100%' src='/static/refreport-logo-green.webp' alt='RefReport Logo' />
            <a href="htpps://refreport.net" rel='noopener noreferrer' target='_blank'>RefReport</a>
            <p>
              Digital reporting solution for sports leagues. 
            </p>
          </div>
          <div className='App-product'>
            <img className='App-ref-clock-image' src='/static/ref-clock-preview.png' alt='RefReport Logo' />
            <a href="https://apps.apple.com/us/app/refclock-timer/id6476637373" rel='noopener noreferrer' target='_blank'>RefClock Referee Timer</a>
            <p>
              Apple watch app for soccer referees.  
            </p>
          </div>
        </section>
      </section>
    </div>
  );
}

export default App;
